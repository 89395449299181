<template>
  <div style="color: #eeeeee; background-color: #18223d">
    <div class="container">
      <div class="row">
        <div class="col-md-4" v-for="(item,index) in dataList" :key="index">
          <div class="box">
            <img :src="item.imgUrl" alt />
            <div style="margin-left: 10px;">
              <div class="title">{{item.title}}</div>
              <p>{{item.text}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commDesc',
  data() {
    return {
      dataList: [
        {
          title: '安全放心',
          text: '支持自动备份和数据加密，放心的选择。',
          imgUrl: require('@/assets/images/icon/a1.png')
        },
        {
          title: '成熟、稳定、易用',
          text: '10余年的成长和有上万的用户。',
          imgUrl: require('@/assets/images/icon/a2.png')
        },
        {
          title: '卓越服务和技术支持',
          text: '优质的客户服务、和专业的技术支持。',
          imgUrl: require('@/assets/images/icon/a3.png')
        }
      ]
    }
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 576px) {
  .box {
    margin: 20px 10px !important;
    img {
      width: 48px !important;
      height: 48px !important;
    }
    .title {
      font-size: 20px !important;
    }
    p {
      font-size: 12px !important;
      margin-top: 5px !important;
      line-height: 20px !important;
    }
  }
}

p {
  margin-bottom: 0;
}
.box {
  margin: 50px 10px;
  display: flex;
  align-items: flex-start;
  .title {
    font-size: 22px;
    margin-bottom: 15 px;
  }
  p {
    font-size: 16px;
    margin-top: 10px;
    line-height: 26px;
  }
}
</style>