<template>
  <div class="container">
    <div class="cate" v-for="(cate,index) in softCates" :key="index">
      <moduleTitle :title="cate.name" />
      <div class="row">
        <div class="col-md-3" v-for="(item,index) in cate.softs" :key="index">
          <soft-card :soft="item" :btnDownload="btnDownload" :btnInfo="btnInfo" :btnBuy="btnBuy"></soft-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moduleTitle from '@/components/moduleTitle'
import softCard from '@/components/softCard'

export default {
  components: {
    moduleTitle,
    softCard
  },
  props: {
    btnDownload: {
      type: Boolean,
      default: true
    },
    btnInfo: {
      type: Boolean,
      default: true
    },
    btnBuy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {
    ...mapGetters(['softCates'])
  },
  created() {
    this.utils.refreshSoftCates()
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 576px) {
}
// .cate:nth-child(2n) {
//   background: #fff;
// }
</style>