<template>
  <div class="soft-item" data-aos="fade-up" data-aos-offset="0" data-aos-delay="50" data-aos-duration="800">
    <div class="title">
      <img :src="utils.getSoftIcon(soft.licenseCode)" alt />
      <span class="item-title">{{soft.name}}</span>
    </div>
    <div class="desc">{{soft.desc}}</div>
    <div class="btns row">
      <div v-if="btnInfo" class="col-md-12 col-6" style="margin-top: 5px;">
        <el-button
          style="width: 100%;"
          v-if="soft.webSite"
          @click="openUrl(`https://${soft.licenseCode}.jiandanke.cn`)"
          type="success"
          size="small"
          icon="el-icon-discover"
        >打开网页版</el-button>
        <el-button v-else style="width: 100%;" type="info" size="small" plain disabled>暂无网页版</el-button>
      </div>
      <div v-if="btnDownload" class="col-md-12 col-6" style="margin-top: 5px;">
        <el-button
          style="width: 100%;"
          @click="openUrl(soft.webSite?`https://${soft.licenseCode}.jiandanke.cn/#/download`:'http://www.veidsoft.com/download')"
          type="primary"
          size="small"
          icon="el-icon-download"
        >下载客户端</el-button>
      </div>
      <div v-if="btnBuy" class="col-md-12 col-6" style="margin-top: 5px;">
        <el-button
          style="width: 100%;"
          @click="openUrl(soft.webSite?`https://${soft.licenseCode}.jiandanke.cn/#/price`:'http://www.veidsoft.com/buy')"
          type="danger"
          size="small"
          icon="el-icon-goods"
        >立即购买</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'softCard',
  props: {
    soft: {
      type: Object,
      default: () => {
        return {}
      }
    },
    btnDownload: {
      type: Boolean,
      default: true
    },
    btnInfo: {
      type: Boolean,
      default: true
    },
    btnBuy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  created() {},
  methods: {
    openUrl(url) {
      let newUrl = url
      if (this.onloadArgs.getInvite()) {
        newUrl = newUrl + `/#/?invite=${this.onloadArgs.getInvite()}`
      }
      window.open(newUrl, '_blank ')
    }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 576px) {
  .soft-item {
    .title {
      margin: 5px !important;
    }
  }
}

.soft-item {
  background: #fff;
  margin-bottom: 10px;
  border: 1px #eee solid;
  padding: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  .title {
    margin: 20px 0;
    img {
      width: 32px;
      height: 32px;
    }
    span {
      line-height: 32px;
      font-size: 18px;
      font-weight: 400;
      padding-left: 10px;
    }
  }
  .desc {
    color: #999;
    font-size: 14px;
    line-height: 22px;
    height: 110px;
    // white-space: nowrap;
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
  }
  .btns {
    margin: 10px 0 0 0;
    .btn {
      width: 100%;
      margin: 5px 0;
    }
  }
}

.soft-item:hover {
  // margin-top: -10px;
  border: 1px solid rgba(21, 138, 253, 0.5);
  box-shadow: 0px 0px 26px rgba(21, 138, 253, 0.25);
}
</style>