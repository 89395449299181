<template>
  <div>
    <h2>{{title}}</h2>
  </div>
</template>

<script>
export default {
  name: 'moduleTitle',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  created() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 576px) {
  h2 {
    font-size: 24px !important;
    margin: 15px 0 !important;
  }
}

h2 {
  width: 100%;
  text-align: center;
  color: #000000;
  font-size: 28px;
  text-align: center;
  line-height: 38px;
  margin: 30px 0;
}
</style>